import { Guid } from "js-guid";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Email from "../../Assets/Images/email.svg";
import PolicyComparision from "../../Assets/Images/policy-comparision.svg";
import PolicySummarize from "../../Assets/Images/policy-summ.svg";
import SnakeBlack from "../../Assets/Images/snake-b.svg";
import SnakeBlue from "../../Assets/Images/snake-blue.svg";
import TalkToDb from "../../Assets/Images/talktodb.svg";
import Task from "../../Assets/Images/task.svg";
import Header from "../../Common/Header";
import HomeSidebar from "../../Common/HomeSidebar";

export default function WidgetsLandingPage() {
  const toast: any = useRef("");
  const navigate = useNavigate();

  const [isTalkToAMSEnabled, setIsTalkToAMSEnabled] = useState<boolean>(
    localStorage.getItem("IsTalkToAMSEnabled") === "true"
  );

  const onPolicyComparisionClick = () => {
    navigate("/policy");
  };

  const onRateAgentClick = () => {
    navigate("/rateagent");
  };
  const onLangchainSQLAgentClick = () => {
    navigate(`/talktoams/${Guid.newGuid()}`);
  };
  // const onXMLAgentClick = () => {
  //   navigate(`/xml/${Guid.newGuid()}`);
  // };
  const onNeo4JAgenctClick = () => {
    navigate(`/neo4j/${Guid.newGuid()}`);
  }
  return (
    <div className="widget-landing-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid d-flex justify-content-center p-0">
        <div className="row main-content mx-auto  w-100 ">
          <div className="col support-bot-sidebar-main-div">
            <HomeSidebar />
          </div>
          <div className="col d-flex justify-content-center ">
            <div className=" d-flex  align-items-center  flex-column  container-fluid w-100 ">
              <div className="row mx-auto mb-2 mt-5 ">
                <div className="col-3 d-flex align-items-center justify-content-center mb-3">
                  <img alt="widget" src={SnakeBlue} className="snake-img" />
                </div>
                <div className="col-6 mb-3">
                  <h4 className="widget-landing-heading">
                    Momentum Insurance <span> A.I. </span>
                    <br /> Agents
                  </h4>
                </div>
                <div className="col-3 d-flex  align-items-center justify-content-center mb-3">
                  <img src={SnakeBlack} className="snake-img" />
                </div>
                <div className="col-3 d-flex  align-items-center justify-content-end ">
                  <img src={Email} className="email-img" />
                </div>
                <div className="col-6 text-center text-20">
                  <span className="second-heading">
                    Automate your agency tasks with our Insurance A.I. agents.
                  </span>
                  <br />
                  <span className="second-heading">
                    Discover and Utilize them in to increase your productivity.
                  </span>
                </div>
                <div className="col-3 d-flex  align-items-center justify-content-end ">
                  <img src={Task} className="email-img" />
                </div>
              </div>

              <div className="row mx-auto mb-2 mt-2 d-flex justify-content-evenly ">
                <div className="col-lg-3 col-md-3 col-sm-4 col-12 mb-2 ">
                  <div
                    className="landing-page-blocks show-cursor"
                    // onClick={onPolicyComparisionClick}
                  >
                    <span className="img-span">
                      <img src={PolicyComparision} className="" />
                    </span>
                    <span className="landing-page-title">
                      Policy Comparison
                    </span>
                    <span className="text-12">
                      This agent provides multiple options like summarizing the
                      policy, or creating a proposal form quote letter. This
                      agent also provides option to compare multiple policies,
                      limits and coverages
                    </span>
                  </div>
                </div>

                <div className="col-lg-3  col-md-3 col-sm-4 col-12 mb-2 ">
                  <div className="landing-page-blocks show-cursor">
                    {" "}
                    <span className="img-span">
                      <img src={PolicySummarize} className="" />
                    </span>
                    <span className="landing-page-title">Vehicle Search</span>
                    <span className="text-12">Coming soon</span>
                  </div>
                </div>
                <div className="col-lg-3  col-md-3 col-sm-4 col-12 mb-2 ">
                  <div
                    className="landing-page-blocks show-cursor"
                    onClick={onRateAgentClick}
                  >
                    <span className="img-span">
                      <img src={TalkToDb} className="" />
                    </span>

                    <span className="landing-page-title">Rate Agent</span>

                    <span className="text-12 d-block ">
                      This AI agent provide a facility to ask a question about
                      rate and provide answer by calling Rate API. It currently
                      allows you to know the appetite and allow you to create
                      the application
                    </span>
                  </div>
                </div>
                {isTalkToAMSEnabled && (
                  <div className="col-lg-3  col-md-3 col-sm-4 col-12 mb-2 ">
                    <div
                      className="landing-page-blocks show-cursor"
                      onClick={onLangchainSQLAgentClick}
                    >
                      <span className="img-span">
                        <img src={TalkToDb} className="" />
                      </span>

                      <span className="landing-page-title">
                        Talk to NowCerts AMS
                      </span>

                      <span className="text-12 d-block ">
                        This AI agent allows agent to ask question and this AI
                        agent converts the natural language questions into SQL
                        and return a data from the database and also creates a
                        charts
                      </span>
                    </div>
                  </div>
                )}
                {/* <div className="col-lg-3  col-md-3 col-sm-4 col-12 mb-2 ">
                  <div
                    className="landing-page-blocks show-cursor"
                    onClick={onXMLAgentClick}
                  >
                    <span className="img-span">
                      <img src={TalkToDb} className="" />
                    </span>

                    <span className="landing-page-title">XML DB</span>

                    <span className="text-12 d-block ">
                      This AI agent allows agent to ask question and this AI
                      agent converts the natural language questions into SQL and
                      return a data from the database and also creates a charts
                    </span>
                  </div>
                </div> */}
                <div className="col-lg-3  col-md-3 col-sm-4 col-12 mb-2 ">
                  <div
                    className="landing-page-blocks show-cursor"
                    onClick={onNeo4JAgenctClick}
                  >
                    <span className="img-span">
                      <img src={TalkToDb} className="" />
                    </span>

                    <span className="landing-page-title">Neo4j Agent</span>

                    <span className="text-12 d-block ">
                      This AI agent allows agent to ask question and this AI
                      agent converts the natural language questions into SQL and
                      return a data from the database and also creates a charts
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
