import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Login from "./Accounts/Login";
import ValidateNowcerts from "./Accounts/ValidateNowcerts";
import "./App.css";
import LangchainSQLAgent from "./Bot-components/Bot-widgets/LangchainSQLAgent";
import PolicyComparision from "./Bot-components/Bot-widgets/PolicyComparision";
import RateAgent from "./Bot-components/Bot-widgets/RateAgent";
import WidgetsLandingPage from "./Bot-components/Bot-widgets/WidgetsLandingPage";
import History from "./Bot-components/History";
import NCChatBot from "./Bot-components/NCChatBot";
import UploadPage from "./Bot-components/UploadPage";
import Neo4jAgent from "./Bot-components/Bot-widgets/Neo4jAgent";
import TalkToDatabaseAgent from "./Bot-components/Bot-widgets/TalkToDatabaseAgent";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/bridge" element={<ValidateNowcerts />}></Route>
          <Route path="/bot/:conversationId" element={<NCChatBot />}></Route>
          <Route path="/bot/:conversationId/embeded" element={<NCChatBot />}></Route>
          <Route path="/upload" element={<UploadPage />}></Route>
          <Route path="/history" element={<History />}></Route>
          <Route path="/agents" element={<WidgetsLandingPage />}></Route>
          <Route path="/policy" element={<PolicyComparision />}></Route>
          <Route path="/rateagent" element={<RateAgent />}></Route>
          <Route path="/talktoams/:conversationId" element={<LangchainSQLAgent />}></Route>
          <Route path="/talktoams/:conversationId/embeded" element={<LangchainSQLAgent />}></Route>
          <Route path="/neo4j/:conversationId" element={<Neo4jAgent />}></Route>
          <Route path="/neo4j/:conversationId/embeded" element={<Neo4jAgent />}></Route>
          <Route path="/talk-to-database/:conversationId" element={<TalkToDatabaseAgent />}></Route>
          <Route path="/talk-to-database/:conversationId/embeded" element={<TalkToDatabaseAgent />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
