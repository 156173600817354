import axios from "axios";
import { Guid } from "js-guid";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import HistoryIcon from "../Assets/Images/Chat-Bot-History.svg";
import WidgetIcon from "../Assets/Images/Layout 3.svg";
import OpenInNewWindowIcon from "../Assets/Images/Open_New_Window.svg";
import StartNewConversation from "../Assets/Images/Start-New-Conversation.svg";
import TrainingContentIcon from "../Assets/Images/TrainingContent.svg";
import UploadIcon from "../Assets/Images/Upload-File.svg";
import FeedbackIcon from "../Assets/Images/feedback.svg";
import SupportLoader from "../CommonControls/SupportLoader";
import CommonValues from "./Utils";
import TalkToDatabase from "../Assets/Images/talk-to-ams-sidebar.svg";
import NCChatBotSidebarIcon from "../Assets/Images/nc-chat-bot-sidebar.svg";
import { BrainCircuit, UserCheck, MessageSquarePlus, DatabaseZap, Clock, Brain, Users, MessageCircle, BookOpen, UploadCloud, SquareArrowOutUpRight, Podcast} from "lucide-react";
export default function HomeSidebar({ setShowConversationHistory, chatBotEmbeded }: any) {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const roleId = localStorage.getItem("RoleId");
  const showHistory =
    window.location.pathname.startsWith("/bot") ||
    window.location.pathname.startsWith("/talktoams") ||
    window.location.pathname.startsWith("/xml") ||
    window.location.pathname.startsWith("/neo4j");

  const [showNCSupportFeedbackBox, setShowNCSupportFeedbackBox] = useState(false);
  const [submitFeedbackLoading, setSubmitFeedbackLoading] = useState(false);
  const [submitRequestLoading, setSubmitRequestLoading] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [trainingContent, setTrainingContent] = useState("");
  const [showNCSupportTrainingContentBox, setShowNCSupportTrainingContentBox] = useState(false);
  const [submitTrainingContentLoading, setSubmitTrainingContentLoading] = useState(false);
  const [typedTextOnScreen, setTypedTextOnScreen] = useState("");
  const [isChatBotPage, setIsChatBotPage] = useState(window.location.pathname.startsWith("/bot"));
  const [isTalkToAMSPage, setIsTalkToAMSPage] = useState(window.location.pathname.startsWith("/talktoams"));
  const [isAgentsPage, setIsAgentsPage] = useState(window.location.pathname.startsWith("/agents"));
  const [isTalkToAMSEnabled, setIsTalkToAMSEnabled] = useState<boolean>(localStorage.getItem("IsTalkToAMSEnabled") === "true");
  const [isTalkToAMSRequested, setIsTalkToAMSRequested] = useState<boolean>(localStorage.getItem("IsTalkToAMSRequested") === "true");
  const [showTalkToAMSRequestBox, setShowTalkToAMSRequestBox] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      setTypedTextOnScreen((prevText) => {
        const newTypedTextOnScreen = prevText + event.key;
        if (newTypedTextOnScreen.toLowerCase().includes(".train")) {
          setShowNCSupportTrainingContentBox(true);
          setTypedTextOnScreen("");
        }
        return newTypedTextOnScreen;
      });
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const onNCChatBotClick = () => {
    chatBotEmbeded ? navigate(`/bot/${Guid.newGuid()}/embeded`) : navigate(`/bot/${Guid.newGuid()}`);
  };
  const onUploadClick = () => {
    navigate("/upload");
  };
  const onHistoryClick = () => {
    setShowConversationHistory(true);
  };
  const onHomeIconClick = () => {
    if (window.location.pathname.startsWith("/bot")) {
      setShowConversationHistory(false);
      chatBotEmbeded ? navigate(`/bot/${Guid.newGuid()}/embeded`) : navigate(`/bot/${Guid.newGuid()}`);
    } else if (window.location.pathname.startsWith("/talktoams")) {
      setShowConversationHistory(false);
      chatBotEmbeded ? navigate(`/talktoams/${Guid.newGuid()}/embeded`) : navigate(`/talktoams/${Guid.newGuid()}`);
    } else if (window.location.pathname.startsWith("/xml")) {
      setShowConversationHistory(false);
      chatBotEmbeded ? navigate(`/xml/${Guid.newGuid()}/embeded`) : navigate(`/xml/${Guid.newGuid()}`);
    } else if (window.location.pathname.startsWith("/neo4j")) {
      setShowConversationHistory(false);
      chatBotEmbeded ? navigate(`/neo4j/${Guid.newGuid()}/embeded`) : navigate(`/neo4j/${Guid.newGuid()}`);
    }
  };
  const onWidgetClick = () => {
    navigate("/agents");
  };
  const onTalkToAMSClick = () => {
    if (isTalkToAMSEnabled) {
      chatBotEmbeded ? navigate(`/talktoams/${Guid.newGuid()}/embeded`) : navigate(`/talktoams/${Guid.newGuid()}`);
    } else {
      setShowTalkToAMSRequestBox(true);
    }
  };
  const onTalkToDatabaseClick = () => {
    chatBotEmbeded ? navigate(`/talk-to-database/${Guid.newGuid()}/embeded`) : navigate(`/talk-to-database/${Guid.newGuid()}`);
  };
  const onOpenInMomentumAICenterClick = () => {
    // setRedirectLoading(true);
    // const token = CommonValues.GetToken();

    // let data = JSON.stringify({
    //   RouteURL: "support-bot",
    // });

    // let config = {
    //   method: "post",
    //   maxBodyLength: Infinity,
    //   url: `${process.env.REACT_APP_AUTHAPI}/api/account/toolboxssotoken`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   data: data,
    // };

    // axios
    //   .request(config)
    //   .then((response: any) => {
    //     let url = `${process.env.REACT_APP_Toolbox_Url}/support-bot-authentication?token=${response.data.token}`;
    //     window.open(url, "_blank");
    //     setRedirectLoading(false);
    //   })
    //   .catch((error: any) => {
    //     setRedirectLoading(false);
    //     if (error.response.status == 401) {
    //       CommonValues.Logout(navigate);
    //     } else {
    //       toast.current.show({
    //         severity: "error",
    //         detail: "Unknown error while redirecting to toolbox",
    //         life: 3000,
    //       });
    //     }
    //   });
    window.open(
      `${window.location.ancestorOrigins[0]}/AMSINS/AI/LandingPreview`,'_blank'
    );
  };
  const onFeedbackClick = () => {
    setShowNCSupportFeedbackBox(true);
  };
  const onTrainingContentClick = () => {
    setShowNCSupportTrainingContentBox(true);
  };
  const onHide = () => {
    setShowNCSupportFeedbackBox(false);
    setShowNCSupportTrainingContentBox(false);
    setShowTalkToAMSRequestBox(false);
  };
  const onFeedbackChange = (feedback: string) => {
    setFeedback(feedback);
  };
  const onTrainingContentChange = (trainingContent: string) => {
    setTrainingContent(trainingContent);
  };
  const onSubmitFeedback = () => {
    setSubmitFeedbackLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({
        Feedback: feedback,
        PageRoute: window.location.pathname,
      }),
    };

    axios(config)
      .then((response: any) => {
        setSubmitFeedbackLoading(false);
        toast.current.show({
          severity: "success",
          detail: "Feedback submitted successfully.",
          life: 3000,
        });
        setFeedback("");
        setShowNCSupportFeedbackBox(false);
      })
      .catch((error: any) => {
        setSubmitFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: `Error occurred while submitting Feedback.`,
              life: 3000,
            });
          }
        }
      });
  };
  const onSubmitRequest = () => {
    setSubmitRequestLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/nctalktoamsagent/nc-talk-to-ams-subscription-request`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSubmitRequestLoading(false);
        toast.current.show({
          severity: "success",
          detail: "Talk to AMS agent request submitted successfully.",
          life: 3000,
        });
        setFeedback("");
        setShowTalkToAMSRequestBox(false);
        setIsTalkToAMSRequested(true);
        localStorage.setItem("IsTalkToAMSRequested", "true");
      })
      .catch((error: any) => {
        setSubmitRequestLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: `Error occurred while submitting request.`,
              life: 3000,
            });
          }
        }
      });
  };
  const onSubmitTrainingContent = () => {
    setSubmitTrainingContentLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-training`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({
        TrainingData: trainingContent,
        PageRoute: window.location.pathname,
      }),
    };

    axios(config)
      .then((response: any) => {
        setSubmitTrainingContentLoading(false);
        toast.current.show({
          severity: "success",
          detail: "Training content submitted successfully. The bot will be trained soon.",
          life: 3000,
        });
        setTrainingContent("");
        setShowNCSupportTrainingContentBox(false);
      })
      .catch((error: any) => {
        setSubmitTrainingContentLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: `Error occurred while submitting training content.`,
              life: 3000,
            });
          }
        }
      });
  };
  return (
    <div className="home-sidebar">
      <Toast ref={toast} />
      <div className="d-flex flex-column align-items-center gap-3 p-2"></div>
      {((isTalkToAMSPage && chatBotEmbeded) || (!chatBotEmbeded && isAgentsPage)) && (
        <div className="support-bot-sidebar mt-4" onClick={onNCChatBotClick}>
          <span className="" title="AI Assistant in training">
            <img src={NCChatBotSidebarIcon} className={`${chatBotEmbeded ? "sidebar-menu-img-embeded" : "sidebar-menu-img"}`} />
          </span>
        </div>
      )}
      {chatBotEmbeded && !isTalkToAMSPage && (
        <div className="support-bot-sidebar mt-4" onClick={onTalkToAMSClick}>
          <span className="" title="Talk to AMS agent">
          <Podcast size={24} color="#A300FF" />

            {/* <img alt="Talk To AMS agent" src={TalkToDatabase} className={`${chatBotEmbeded ? "sidebar-menu-img-embeded" : "sidebar-menu-img"}`} /> */}
          </span>
        </div>
      )}
      {!isAgentsPage && (
        <div className={`support-bot-sidebar${(isTalkToAMSPage || isChatBotPage) && chatBotEmbeded ? "" : " mt-4"}`} onClick={onHomeIconClick}>
          <span className="" title="Start New Conversation">
            <MessageSquarePlus size={24} color="#A300FF" />
            {/* <img
              src={StartNewConversation}
              className={`${
                chatBotEmbeded ? "sidebar-menu-img-embeded" : "sidebar-menu-img"
              }`}
            /> */}
          </span>
        </div>
      )}
      {chatBotEmbeded && (
        <div className="support-bot-sidebar mt-4" onClick={onTalkToDatabaseClick}>
          <span className="" title="Talk to Database">
            <DatabaseZap size={24} color="#A300FF" />
          </span>
        </div>
      )}
      {showHistory && (
        <div className="support-bot-sidebar" onClick={onHistoryClick}>
          <span className="" title="History">
            <Clock size={24} color="#A300FF" />
          </span>
        </div>
      )}
      {chatBotEmbeded && (
        <div className="support-bot-sidebar mt-2" onClick={onOpenInMomentumAICenterClick}>
          {redirectLoading ? (
            <SupportLoader />
          ) : (
            <span className="" title="Open AI assistant in training">
                <SquareArrowOutUpRight size={24} color="#A300FF" />
                
              {/* <img
                alt="AI assistant in training"
                src={OpenInNewWindowIcon}
                className={`${chatBotEmbeded ? "sidebar-menu-img-embeded" : "sidebar-menu-img"}`}
              /> */}
            </span>
          )}
        </div>
      )}
      {!chatBotEmbeded && !isAgentsPage && (
        <div className="support-bot-sidebar mt-2" onClick={onWidgetClick}>
          <span className="" title="Agents">
            <img alt="Widgets" src={WidgetIcon} className={`${chatBotEmbeded ? "sidebar-menu-img-embeded" : "sidebar-menu-img"}`} />
          </span>
        </div>
      )}
      <div className="support-bot-sidebar mt-3" onClick={onFeedbackClick}>
        <span className="" title="Feedback">
          <MessageCircle size={24} color="#A300FF" />
          
        </span>
      </div>
      {isChatBotPage && (
        <div className="support-bot-sidebar mt-3" onClick={onTrainingContentClick}>
          <span className="" title="Training Content">
          <BookOpen size={24} color="#A300FF" />
            {/* <img src={TrainingContentIcon} className={`${chatBotEmbeded ? "sidebar-menu-img-embeded" : "sidebar-menu-img"}`} /> */}
          </span>
        </div>
      )}

      {roleId === "1" && !(window.location.pathname === "/upload") && (
        <div className="support-bot-sidebar upload-icon mt-2" onClick={onUploadClick}>
          <span className="" title="Upload">
            <img alt="Upload File" src={UploadIcon} className={`${chatBotEmbeded ? "sidebar-menu-img-embeded" : "sidebar-menu-img"}`} />
          </span>
        </div>
      )}
      <div className="nc-support-feedback-box-embeded">
        <Dialog
          visible={showNCSupportFeedbackBox}
          className="nc-sidebar-dialog-box"
          header={<div className={`${chatBotEmbeded ? "text-14" : "text-16"}`}>Your feedback will improve this product</div>}
          onHide={() => onHide()}
          style={{ width: `${chatBotEmbeded ? "83vw" : "25vw"}` }}
          draggable={false}
        >
          <div className="row mt-1">
            <div className="col-md-12">
              <textarea
                name="feedback"
                onChange={(e) => onFeedbackChange(e.target.value)}
                value={feedback}
                className={`form-control mb-3 nc-support-text-area ${chatBotEmbeded ? "text-14" : "text-16"}`}
                rows={4}
                placeholder=""
                autoFocus={true}
              />
            </div>
            <div className="col-md-12 text-end">
              {submitFeedbackLoading ? (
                <div className="d-flex justify-content-end">
                  <SupportLoader />
                </div>
              ) : (
                <button title="Submit you feedback" onClick={onSubmitFeedback} className="btn feedback-btn">
                  <div className={`${chatBotEmbeded ? "text-14" : "text-16"}`}>Submit</div>
                </button>
              )}
            </div>
          </div>
        </Dialog>{" "}
        <Dialog
          visible={showNCSupportTrainingContentBox}
          className="nc-sidebar-dialog-box"
          header={<div className={`${chatBotEmbeded ? "text-14" : "text-16"}`}>Your training content will improve this product</div>}
          style={{ width: `${chatBotEmbeded ? "83vw" : "50vw"}` }}
          onHide={() => onHide()}
          draggable={false}
        >
          <div className="row mt-1">
            <div className="col-md-12">
              <textarea
                name="Training content"
                onChange={(e) => onTrainingContentChange(e.target.value)}
                value={trainingContent}
                className={`form-control mb-3 nc-support-text-area ${chatBotEmbeded ? "text-14" : "text-16"}`}
                rows={chatBotEmbeded ? 25 : 15}
                placeholder="Enter data for training..."
                autoFocus={true}
              />
            </div>
            <div className="col-md-12 text-end">
              {submitTrainingContentLoading ? (
                <div className="d-flex justify-content-end">
                  <SupportLoader />
                </div>
              ) : (
                <button title="Submit you training content" onClick={onSubmitTrainingContent} className="btn submit-btn">
                  <div className={`${chatBotEmbeded ? "text-14" : "text-16"}`}>Submit</div>
                </button>
              )}
            </div>
          </div>
        </Dialog>{" "}
        <Dialog
          visible={showTalkToAMSRequestBox}
          className="nc-sidebar-dialog-box"
          header={
            <div className={`${chatBotEmbeded ? "text-14" : "text-16"}`}>
              {isTalkToAMSRequested ? "Talk to AMS agent is requested by your agency" : "Would you like to request Talk To AMS agent?"}
            </div>
          }
          onHide={() => onHide()}
          style={{ width: `${chatBotEmbeded ? "83vw" : "25vw"}` }}
          draggable={false}
        >
          <div className="row">
            {isTalkToAMSRequested ? (
              <span className="text-12">Please contact admin for further updates.</span>
            ) : (
              <div className="text-center">
                {submitRequestLoading ? (
                  <div className="d-flex justify-content-center">
                    <SupportLoader />
                  </div>
                ) : (
                  <button title="Submit you feedback" onClick={onSubmitRequest} className="btn submit-btn w-50">
                    <div className={`${chatBotEmbeded ? "text-14" : "text-16"}`}>Submit Request</div>
                  </button>
                )}
              </div>
            )}
          </div>
        </Dialog>{" "}
      </div>
    </div>
  );
}
