import { Guid } from "js-guid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NCLogo from "../Assets/Images/Momentum AI Icon-bk.png";
import MomentumLogo from "../Assets/Images/Nowcerts.png";
import CommonValues from "./Utils";

export default function Header({ setShowConversationHistory, chatBotEmbeded }: any) {
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState<string | null>("");
  const [isChatBotPage, setIsChatBotPage] = useState(false);
  const [isPolicyComparePage, setIsPolicyComparePage] = useState(false);
  const [isTalkToAMSPage, setIsTalkToAMSPage] = useState(false);
  const [isAgentsPage, setIsAgentsPage] = useState(false);
  const [isXMLAgentPage, setIsXMLAgentPage] = useState(false);
  const [isNeo4jAgentPage, setIsNeo4jAgentPage] = useState(false);

  useEffect(() => {
    setBusinessName(localStorage.getItem("BusinessName"));
    setIsChatBotPage(window.location.pathname.substring(0, 4) === "/bot" || window.location.pathname === "/upload");
    setIsPolicyComparePage(window.location.pathname === "/policy");
    setIsTalkToAMSPage(window.location.pathname.startsWith("/talktoams"));
    setIsXMLAgentPage(window.location.pathname.startsWith("/xml"));
    setIsNeo4jAgentPage(window.location.pathname.startsWith("/neo4j"));
    setIsAgentsPage(window.location.pathname === "/agents");
  }, []);

  const onLogoClick = () => {
    if (window.location.pathname.substring(0, 4) === "/bot") {
      setShowConversationHistory(false);
    }
    let conversationId = Guid.newGuid();
    chatBotEmbeded ? navigate(`/bot/${conversationId}/embeded`) : navigate(`/bot/${conversationId}`);
  };
  const onLogoutClick = () => {
    CommonValues.Logout(navigate);
  };
  return (
    <div className="row support-bot-header p-3 mx-auto">
      <div className={`${chatBotEmbeded ? "col-lg-12 col-md-12 col-sm-12 col-12" : "col-lg-8 col-md-8 col-sm-8 col-8"} d-flex align-items-center`}>
        {chatBotEmbeded ? (
          <div onClick={onLogoClick} className="show-cursor">
            <img src={NCLogo} className="header-momentum-logo me-2" />
            {isTalkToAMSPage ? <b>AI Center {isTalkToAMSPage ? "(Talk To AMS)" : "(Assistant in training)"}</b> : <b>Support Assistance</b>}
          </div>
        ) : (
          <>
            <img src={MomentumLogo} className="header-momentum-logo me-2" onClick={onLogoClick} />
            <span className="fw-bold">
              {" "}
              {isChatBotPage
                ? "| Support Chat Bot"
                : isPolicyComparePage
                ? "| Policy Comparison"
                : isTalkToAMSPage
                ? "| Talk To AMS"
                : isAgentsPage
                ? "| Agents"
                : isXMLAgentPage
                ? "| XML Agent"
                : isNeo4jAgentPage
                ? "| Neo4j Agent"
                : ""}
            </span>
          </>
        )}
      </div>
      {!chatBotEmbeded && (
        <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-end">
          {CommonValues.GetToken() != null ? (
            <>
              <span className="p-4">
                Welcome <b>{businessName}</b>
              </span>
              <input className="btn submit-btn" type="button" value="Logout" onClick={onLogoutClick} />
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}
