import DOMPurify from "dompurify";
import { Sidebar } from "primereact/sidebar";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import Plot from "react-plotly.js";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import SupportLoader from "../CommonControls/SupportLoader";

interface AnswerProps {
  chatResponse: ChatResponse;
  index: number;
  chatBotEmbeded: boolean;
  sendQuestion: (question: string) => void;
  onFeedbackSubmit: (chatId: string, feedBack: string) => void;
  onFeedbackChange: (chatId: string, feedBack: string) => void;
  toggleFeedbackVisibility: (chatId: string) => void;
  onCopyResponseClick: (copiedData: string) => void;
  onThumbsUpClick: (chatId: string, like: boolean, disLike: boolean) => void;
  onThumbsDownClick: (chatId: string, like: boolean, disLike: boolean) => void;
  showImageSidebar: (index: number, isHidden: boolean) => void;
}

interface ChatResponse {
  conversationId: string;
  answer: string;
  sources: string[];
  imageURLs: string[];
  videoURLs: string[];
  imageSidebar: boolean;
  question: string;
  chatId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
  plotlyData: { data: string; layout: string };
  textToAction: string;
  responseStreaming: boolean;
}

const Answer: React.FC<AnswerProps> = ({
  chatResponse,
  index,
  chatBotEmbeded,
  sendQuestion,
  onFeedbackSubmit,
  onFeedbackChange,
  toggleFeedbackVisibility,
  onCopyResponseClick,
  onThumbsUpClick,
  onThumbsDownClick,
  showImageSidebar,
}) => {
  const parseAnswerToHtml = (answer: string) => {
    let parsedAnswer = answer.trim();

    return parsedAnswer;
  };
  let isTalkToDatabasePage : boolean = window.location.href.includes("talk-to-database")
  const parsedAnswer = useMemo(() => parseAnswerToHtml(chatResponse.answer), [chatResponse]);
  let sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer);
  let followUpQuestions: string[] = [];
  if (sanitizedAnswerHtml.includes("$$FollowupQuestions$$")) {
    const answerParts = sanitizedAnswerHtml.split("$$FollowupQuestions$$");
    sanitizedAnswerHtml = answerParts[0];
    let followUpPart = answerParts[1].split("$$FollowupQuestionsEND$$")[0];
    followUpQuestions = followUpPart
      ? followUpPart
          .trim()
          .split("\n")
          .filter((q) => q)
      : [];
  } else if (chatResponse.answer.includes("$$PlotlyDataStart$$")) {
    let splitedAnswer = chatResponse.answer.split("$$PlotlyDataStart$$");
    sanitizedAnswerHtml = splitedAnswer[0];
  } else {
    const answerParts = sanitizedAnswerHtml.split("FollowupQuestions");
    sanitizedAnswerHtml = answerParts[0];
    followUpQuestions = answerParts[1]
      ? answerParts[1]
          .trim()
          .split("\n")
          .filter((q: any) => q)
      : [];
  }

  const onSourceClick = (source: string) => {
    window.open(source, "_blank");
  };
  const onFollowupQuestionClick = (followupQuestion: any) => {
    sendQuestion(followupQuestion.substring(3));
  };

  const performTextToAction = (textToAction: string) => {
    if (textToAction === "INSURED_DETAILS") {
      window.open(`${window.location.ancestorOrigins[0]}/AMSINS/Insureds/Insert?MomentumUrl=/Insureds/List`, "_blank");
    } else if (textToAction === "PROSPECT_DETAILS") {
      window.open(`${window.location.ancestorOrigins[0]}/AMSINS/Prospects/Insert?MomentumUrl=/Prospects/List`, "_blank");
    } else if (textToAction === "POLICY_DETAILS") {
      window.open(`${window.location.ancestorOrigins[0]}/Policies/Insert.aspx?MomentumUrl=/Policies/List`, "_blank");
    }
  };

  return (
    <div className="mx-2">
      <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} className="answer-block">
        {sanitizedAnswerHtml}
      </ReactMarkdown>
      {chatResponse.plotlyData.data !== "" &&
        (chatBotEmbeded ? (
          <Plot data={chatResponse.plotlyData.data} layout={chatResponse.plotlyData.layout} style={{ width: "100%", height: "450px" }} />
        ) : (
          <Plot data={chatResponse.plotlyData.data} layout={chatResponse.plotlyData.layout} />
        ))}
      {chatResponse.imageSidebar && (
        <Sidebar visible={chatResponse.imageSidebar} position="right" onHide={() => showImageSidebar(index, false)} style={{ width: "30%" }}>
          {chatResponse.imageURLs.length > 0 && (
            <>
              {chatResponse.imageURLs.map((image, i) => (
                <div className="row" key={i + 1}>
                  <span className="text-center">Image {i + 1}</span>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center mb-3">
                    <img alt="NowCerts Support Center" src={image} height="100%" width="100%" />
                  </div>
                </div>
              ))}
            </>
          )}
        </Sidebar>
      )}
      {chatResponse.textToAction !== "" &&
        (chatResponse.textToAction === "INSURED_DETAILS" ||
          chatResponse.textToAction === "PROSPECT_DETAILS" ||
          chatResponse.textToAction === "POLICY_DETAILS") && (
          <div
            className="sources-box me-3 "
            onClick={() => performTextToAction(chatResponse.textToAction)}
          >
            <span className="source-wrap d-flex">
              <span className="namebox">
                Go to add{" "}
                {`${
                  chatResponse.textToAction === "INSURED_DETAILS"
                    ? "insured"
                    : chatResponse.textToAction === "PROSPECT_DETAILS"
                    ? "prospect"
                    : chatResponse.textToAction === "POLICY_DETAILS"
                    ? "policy"
                    : ""
                }`}
              </span>
            </span>
          </div>
        )}
      {(chatResponse.sources.length > 0 || chatResponse.imageURLs.length > 0 || chatResponse.videoURLs.length > 0) && (
        <>
          <h6>Sources:</h6>

          {chatResponse.sources.length > 0 &&
            !chatResponse.responseStreaming &&
            chatResponse.sources.map((source, i) => (
              <div key={i} className="sources-box me-3" onClick={() => onSourceClick(source)}>
                <span className="source-wrap d-flex">
                  <span className="namebox">Article {i + 1}</span>
                </span>
              </div>
            ))}

          {chatResponse.imageURLs.length > 0 && (
            <div className="sources-box me-3" onClick={() => showImageSidebar(index, true)}>
              <span className="source-wrap d-flex">
                <span className="namebox">Images</span>
              </span>
            </div>
          )}

          {chatResponse.videoURLs.length > 0 &&
            chatResponse.videoURLs.map((source, i) => (
              <div key={i} className="sources-box me-3" onClick={() => onSourceClick(source)}>
                <span className="source-wrap d-flex">
                  <span className="namebox">Video {i + 1}</span>
                </span>
              </div>
            ))}
        </>
      )}
      {!isTalkToDatabasePage && <div className="icon-box mb-2">
        <span
          className={`show-cursor answer-feedback-icon-box ${
            chatResponse.like ? "active" : ""
          }`}
          title="Like"
          onClick={() =>
            onThumbsUpClick(chatResponse.chatId, !chatResponse.like, false)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill={chatResponse.like ? "#5d5d5d" : "none"}
            stroke={chatResponse.like ? "#5d5d5d" : "currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M7 10v12" />
            <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
          </svg>
        </span>
        <span
          className="show-cursor answer-feedback-icon-box"
          title="Dislike"
          onClick={() =>
            onThumbsDownClick(chatResponse.chatId, false, !chatResponse.disLike)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill={chatResponse.disLike ? "#5d5d5d" : "none"}
            stroke={chatResponse.disLike ? "#5d5d5d" : "currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M17 14V2" />
            <path d="M9 18.12 10 14H4.17a2 2 0 0 1-1.92-2.56l2.33-8A2 2 0 0 1 6.5 2H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.76a2 2 0 0 0-1.79 1.11L12 22h0a3.13 3.13 0 0 1-3-3.88Z" />
          </svg>
        </span>
        <span
          className="show-cursor answer-feedback-icon-box"
          title="Copy chatResponse"
          onClick={() => onCopyResponseClick(sanitizedAnswerHtml)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
            <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
          </svg>
        </span>
        <span
          className="show-cursor answer-feedback-icon-box"
          title="Feedback"
          onClick={() => toggleFeedbackVisibility(chatResponse.chatId)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
          </svg>
        </span>
        {chatResponse.showFeedback && (
          <div className="feedback-box mb-3">
            <div className="row">
              <div className="col-12">
                <span className="close-feedback" onClick={() => toggleFeedbackVisibility(chatResponse.chatId)}>
                  {" "}
                  <i className="fa fa-close " aria-hidden="true" title="Close"></i>
                </span>
                <h6>Your feedback will improve this product.</h6>
                <div className="">
                  <textarea
                    name="feedback"
                    onChange={(e) => onFeedbackChange(chatResponse.chatId, e.target.value)}
                    value={chatResponse.feedback}
                    className="form-control mb-3 "
                    rows={4}
                    placeholder="Your feedback..."
                  />
                </div>
                {chatResponse.feedbackLoading ? (
                  <div className="col-12 d-flex justify-content-end">
                    <SupportLoader />
                  </div>
                ) : (
                  <div className="col-12 text-end">
                    <input
                      className="btn ai-feedback-btn"
                      type="button"
                      value="Submit"
                      onClick={() => onFeedbackSubmit(chatResponse.chatId, chatResponse.feedback)}
                    />
                  </div>
                )}
              </div>
            </div>{" "}
          </div>
        )}
      </div>}
      {followUpQuestions.length > 0 && (
        <>
          <h6>Follow-up Questions:</h6>

          {followUpQuestions.map((question, i) => (
            <span key={i} className="followup-que" onClick={() => onFollowupQuestionClick(question)}>
              {question}
            </span>
          ))}
        </>
      )}{" "}
      {chatResponse.responseStreaming && (
        <div className="d-flex justify-content-center">
          <SupportLoader />
        </div>
      )}
    </div>
  );
};

export default Answer;