import axios from "axios";
import { Guid } from "js-guid";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MomentumLogo from "../Assets/Images/momentumAI_Full_Logo_Bk.png";
import AccessDenied from "../Assets/Images/not-allowed-symbol.svg";
import Header from "../Common/Header";
import CommonValues from "../Common/Utils";
import Loader from "../CommonControls/SupportLoader";
import momentumailoading from "../Assets/Images/momentum-ai-loading.gif";
export default function ValidateNowcerts() {
  const [loading, setLoading] = useState(false);
  const toast: any = useRef(null);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const embeded = searchParams.get("embeded") === "true";

  useEffect(() => {
    CommonValues.ClearAllValues();
    validateNowcertsToken(token);
  }, []);

  const getNcToken = (ncSetupUserName: any) => {
    let token = CommonValues.GetToken();
    const data = JSON.stringify({
      NCUserName: ncSetupUserName,
    });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/verifyMomentumCreds`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (response.data != "" && response.data != null) {
          localStorage.setItem("NCToken", response.data.access_token);
          toast.current.show({
            severity: "success",
            detail: "Got NowCerts Token.",
            life: 3000,
          });
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          }
        }
      });
  };
  const checkTalkToAMSSubscriptionRequest = (token: string) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/nctalktoamsagent/check-nc-talk-to-ams-subscription-request`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response) {
          localStorage.setItem("IsTalkToAMSRequested", String(response.data));
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          }
        }
      });
  };
  const getWebsiteDomain = () => {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}`;
  };

  const validateNowcertsToken = (token: any) => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/validateNowcertsToken?token=${token}`,
    };

    axios(config)
      .then((response: any) => {
        if (response.data.token == "") {
          CommonValues.Logout(navigate);
        } else {
          localStorage.setItem("SocialLogin", "true");
          localStorage.setItem("igtoken", response.data.token);
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem("UserName", response.data.userName);
          localStorage.setItem("RoleId", response.data.roleId);
          localStorage.setItem("AvatarName", response.data.avatarName);
          localStorage.setItem("BusinessName", response.data.firstName + " " + response.data.lastName);
          localStorage.setItem("NowCertsAgencyId", response.data.nowCertsAgencyId);
          localStorage.setItem("IsTalkToAMSEnabled", response.data.isTalkToAMSEnabled);
          let conversationId = Guid.newGuid();
          getNcToken(response.data.userName);
          checkTalkToAMSSubscriptionRequest(response.data.token);
          if (embeded) {
            navigate(`/bot/${conversationId}/embeded`, {
              state: { embeded },
            });
          } else {
            navigate(`/bot/${conversationId}`, { state: { embeded } });
          }
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          }
        }
      });
  };
  return (
    <div className="ai-wrapper min-vh-100 d-flex align-items-center justify-content-center">
      <div className="ai-wrapper-container container-fluid position-relative z-1">
        <div className="row g-4 justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="ai-login-box bg-white p-4 rounded shadow text-center">
              {loading ? (
                <>
                  {/* <h1 className="">Momentum AI </h1> */}
                  <img src={MomentumLogo} height={60} />

                  <h3>Launching</h3>
                  <div className="d-flex  justify-content-center">
                    <img src={momentumailoading} height={48} />
                  </div>
                </>
              ) : (
                <>
                  <img src={AccessDenied} height={35} width={35} />
                  <p className="not-authorize-text">
                    <b>You are not authorized</b>
                  </p>
                  <p className="d-block">
                    It seems like you don't have permission to use this portal.
                    <br /> Please sign in with a different account.{" "}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
